import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import toast from 'react-hot-toast';
import {
    Urls,
    RequestState,
    Hosts,
    StorageKeys,
    getWalletSigninMessage,
    getWalletSigninNonce,
    ModalTypes,
    AddressZero,
    LoginTypes,
    WalletConnectProjectId,
    NetworkInfoByChainId,
    CurrentNetwork,
    CurrentNetworkInfo,
    IsPLASMLive,
    WalletConnectionTypes,
    CurrencyType,
    IsNFTStakingLive,
    ThetaSwap,
    ContractAddresses,
    ValidatorStakeManagerContract, ChainRegistrarOnMainchainContract, SubchainId, get7734GuardianTier
} from "../../constants";
import {BigNumber, ethers, FixedNumber, providers} from "ethers";
import BigNumberJS from 'bignumber.js';
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as thetajs from '@thetalabs/theta-js';
import Storage from '../../utils/storage';
import User, {FINISH_OAUTH} from "./user";
import {pushTo} from "../../utils/history";
import * as ThetaPass from "@thetalabs/theta-pass";
import Nft from "./nft";
import Config, {selectConfig, selectContract, selectContractByKey, selectNFTContracts} from "./config";
import {store} from "../index";
import UIState from "../uiState";
import {RoundedButton} from "../../components/Button";
import {
    formatBalance,
    formatWeiWithoutCommas,
    getTimestampAtBlock,
    isMobileBrowser, preloadImage,
    truncateToDigits
} from "../../utils";
import Nfts from "./nft";
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import storage from "../../utils/storage";
import Analytics from "./analytics";
import {fromWei, toWei} from "@thetalabs/theta-js/src/utils";

class TPMCError extends Error {
    constructor(message) {
        super(message);
        this.name = "TPMCError";
    }
}

const getErrorMessage = (e, backupMessage) => {
    console.log('getErrorMessage, e: ', e, 'backupMessage: ', backupMessage);
    if(e instanceof TPMCError){
        console.log('TPMCError', e.message);
        return e.message;
    }
    return backupMessage;
}

const getReduxState = () => {
    return store.getState();
}

const buildThetaProvider = () => {
    const providerRPC = {
        name: CurrentNetworkInfo.chainId,
        rpc: CurrentNetworkInfo.ethRpc,
        chainId: CurrentNetworkInfo.chainIdNum,
    };
    const provider = new providers.JsonRpcProvider(
        providerRPC.rpc,
        {
            chainId: providerRPC.chainId,
            name: `theta-${providerRPC.name}`,
        }
    );
    return provider;
}

const buildReadonlyProvider = (address) => {
    const providerRPC = {
        name: CurrentNetworkInfo.chainId,
        rpc: CurrentNetworkInfo.ethRpc,
        chainId: CurrentNetworkInfo.chainIdNum,
    };
    const provider = new providers.JsonRpcProvider(
        providerRPC.rpc,
        {
            chainId: providerRPC.chainId,
            name: `theta-${providerRPC.name}`,
        }
    );
    // Fake signer
    // create a signer that has the address of the user
    provider.getSigner = () => {
        return new ethers.VoidSigner(address, provider);
    };
    provider.readonly = true;

    return provider;
}

// ===========================
// HELPERS
// ===========================

function getValidatorAddressForAccount(address, validators) {
    validators = _.sortBy(validators, (v) => v);
    return _.first(validators);
}

async function getValidatorAddresses(chainRegistrarOnMainchainContract) {
    const {dynasty} = await chainRegistrarOnMainchainContract.getDynasty();
    const {
        validators,
        shareAmounts
    } = await chainRegistrarOnMainchainContract.getValidatorSet(SubchainId, dynasty);
    console.log('validators', validators);
    console.log('shareAmounts', shareAmounts);

    return validators;
}

async function switchEthereumChain(chainId) {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
        });
        // Metamask takes some time to switch chains
        await new Promise(r => setTimeout(r, 3000));
    } catch (e) {
        if (e.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x169', // 361 in decimal
                            chainName: 'Theta Mainnet',
                            nativeCurrency: {
                                name: 'TFUEL',
                                symbol: 'TFUEL',
                                decimals: 18
                            },
                            blockExplorerUrls: ['https://explorer.thetatoken.org'],
                            rpcUrls: ['https://eth-rpc-api.thetatoken.org/rpc'],
                        },
                    ],
                });
            } catch (addError) {
                console.error(addError);
            }
        }
        console.error(e)
    }
}

export const createWalletConnectV2Provider = async (dispatch) => {
    const wcProvider = await EthereumProvider.init({
        projectId: WalletConnectProjectId,
        chains: [`${NetworkInfoByChainId[CurrentNetwork].chainIdNum}`],
        showQrModal: !isMobileBrowser(),
        rpcMap: {
            ["361"]: NetworkInfoByChainId['mainnet'].ethRpc,
            ["365"]: NetworkInfoByChainId['testnet'].ethRpc,
            ["366"]: NetworkInfoByChainId['privatenet'].ethRpc,
        },
        metadata: {
            name: "Passaways / TPMC",
            description: "The Passaways Misadventure Club",
            url: 'passaways.com',
            icons: ["https://www.passaways.com/android-chrome-512x512.png"],
        }
    });

    wcProvider.on("connect", (event) => {
        console.log("connect wc", event);
    });
    wcProvider.on("disconnect", (event) => {
        console.log("disconnect wc", event)
        dispatch(User.actions.logout());
    });
    wcProvider.on("chainChanged", (event) => {
        console.log("chainChanged wc", event)
        // dispatch(User.actions.logout());
    });
    wcProvider.on("accountsChanged", (event) => {
        console.log("accountsChanged wc", event)
        // dispatch(User.actions.logout());
    });
    wcProvider.on('display_uri', (uri)=> {
        if(isMobileBrowser()){
            dispatch(UIState.actions.showModal(ModalTypes.MobileWallets, {
                uri: uri,
            }));
        }
    });

    return wcProvider
}

const showLoader = (title, subtitle) => {
    store.dispatch(UIState.actions.showLoader(title, subtitle));
}

const hideLoader = () => {
    store.dispatch(UIState.actions.hideLoader());
}

const sleepUntil = async (f, timeoutMs) => {
    return new Promise((resolve, reject) => {
        const timeWas = new Date();
        const wait = setInterval(function() {
            if (f()) {
                console.log("resolved after", new Date() - timeWas, "ms");
                clearInterval(wait);
                resolve();
            } else if (new Date() - timeWas > timeoutMs) { // Timeout
                console.log("rejected after", new Date() - timeWas, "ms");
                clearInterval(wait);
                reject();
            }
        }, 20);
    });
}

const showWalletNotSupportedAlert = (loginType) => {
    store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
        title: `${loginType} is read-only`,
        body: <span>
            {`You must prove ownership with a non-custodial wallet.\n\nPlease withdraw your NFTs from ${loginType} \n and send them to a supported wallet:\n`}
            <div style={{fontSize: 14, marginTop: 8}}>
                Theta Wallet (Mobile)
                <br/>
                MetaMask (Desktop / Mobile)
            </div>
        </span>,
        buttons: [
            <RoundedButton title={'Okay'}
                           color={'grey'}
                           size={'medium'}
                           style={{width: 132}}
                           onClick={() => store.dispatch(UIState.actions.hideModal())}
            />
        ]
    }));
}

const wrapSendTransaction = async (transactionSenderFn) => {
    const state = getReduxState();
    const loginType = selectLoginType(state);
    const provider = selectProvider(state);

    if(provider.readonly !== true){
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        try{
            // Check TFUEL balance but don't care if it fails...
            const tfuelBalance = await provider.getBalance(address);
            if(tfuelBalance.toString() === '0'){
                store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
                    title: `No TFUEL`,
                    body: `You must have TFUEL to send transactions. Please deposit TFUEL into your wallet.`,
                    buttons: [
                        <RoundedButton title={'Okay'}
                                       color={'grey'}
                                       size={'medium'}
                                       style={{width: 132}}
                                       onClick={() => store.dispatch(UIState.actions.hideModal())}
                        />
                    ]
                }));
            }
        }
        catch (e){
            // ignore
        }

        let appSuffix = '';
        if(loginType === LoginTypes.ThetaWallet){
            appSuffix = '\non your Theta Wallet app';
        }
        else if(loginType === LoginTypes.MetaMask){
            appSuffix = '\non MetaMask';
        }
        showLoader('Pending...', `Please review and sign the transaction${appSuffix}.`);
        const txResponse = await transactionSenderFn();
        showLoader('Waiting...', 'Transaction is being processed.');
        return await txResponse.wait(2);
    }
    else{
        showWalletNotSupportedAlert(loginType);
        throw new TPMCError(`Cannot send transactions while logged in with ${loginType}.`);
    }
}

export const showTransphormConfirmationModal = async () => {
    let hasResponded = false;
    let approved = false;
    const onClose = () => {
        hasResponded = true;
    };
    const onApprove = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
        approved = true;
    };
    const onReject = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
    };
    store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
        title: '',
        body: <span>
            <span>{`Transphorming your Passaway is irreversible.\n`}</span>
            <div style={{color: '#FF2965', marginTop: 8}}>I understand: </div>
            <div style={{fontSize: 14, marginTop: 8}}>
                This Passaway will be burned
                <br/>
                All unlocked Perma or Ephemeral Power-up Slots on this passaway will be burned
            </div>
        </span>,
        buttons: [
            <RoundedButton title={'Decline'}
                           color={'red'}
                           size={'medium'}
                           style={{width: 132}}
                           onClick={onReject}
            />,
            <RoundedButton title={`Agree`}
                           color={'green'}
                           size={'medium'}
                           style={{width: 132}}
                           onClick={onApprove}
            />
        ],
        onClose: onClose,
    }));
    await sleepUntil(() => {
        return hasResponded === true;
    }, 600000);

    return approved;
}

export const showApprovalModal = async (title, body) => {
    let hasResponded = false;
    let approved = false;
    const onClose = () => {
        hasResponded = true;
    };
    const onApprove = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
        approved = true;
    };
    const onReject = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
    };
    store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
        title: title,
        body: body,
        buttons: [
            <RoundedButton title={'Reject'}
                           color={'red'}
                           size={'medium'}
                           style={{width: 132}}
                           onClick={onReject}
            />,
            <RoundedButton title={`Approve`}
                           color={'green'}
                           size={'medium'}
                           style={{width: 132}}
                           onClick={onApprove}
            />
        ],
        onClose: onClose,
    }));
    await sleepUntil(() => {
        return hasResponded === true;
    }, 60000);

    return approved;
}

export const isNFTApprovedForAll = async (contract, owner, spender) => {
    return contract.isApprovedForAll(owner, spender);
}

export const checkNFTApproval = async (contract, owner, spender) => {
    if(contract.provider.readonly){
        const state = getReduxState();
        const loginType = selectLoginType(state);

        showWalletNotSupportedAlert(loginType);
        throw new TPMCError(`Cannot send transactions while logged in with ${loginType}.`);
    }

    showLoader('Checking approval...');

    const isApproved = await isNFTApprovedForAll(contract, owner, spender);
    if(!isApproved){
        const spenderContractInfo = getContractInfoByAddress(spender);
        const contractInfo = getContractInfoByAddress(contract.address);

        const body = `${spenderContractInfo.name} requires your approval to transfer your ${contractInfo.name}.\n\nYou can revoke this permission in the settings.`;
        const approved = await showApprovalModal('Approval Required', body);

        // Approve all
        if(approved){
            showLoader('Requesting approval...', 'Please review and sign the transaction.');
            const txResponse = await contract.setApprovalForAll(spender, true);
            showLoader('Waiting...', 'Transaction is being processed.');
            await txResponse.wait(2);

            store.dispatch(Analytics.actions.track('setApprovalForAll', {
                nftName: contractInfo.name,
                spender: spender,
                target: contract.address
            }));
        }
        else{
            hideLoader();
            throw Error('Did not approve');
        }
    }
}

export const isTokenApprovedForAmount = async (contract, owner, spender, amount) => {
    const allowance = await contract.allowance(owner, spender);
    return allowance.gte(amount);
}

export const checkTokenApproval = async (contract, owner, spender, amount, tokenName = 'PLASM') => {
    if(contract?.provider.readonly){
        const state = getReduxState();
        const loginType = selectLoginType(state);

        showWalletNotSupportedAlert(loginType);
        throw new TPMCError(`Cannot send transactions while logged in with ${loginType}.`);
    }

    showLoader('Checking allowance...');
    const allowance = await contract.allowance(owner, spender);
    if(allowance.lt(amount)){
        const contractInfo = getContractInfoByAddress(spender);

        const body = `${contractInfo.name} requires your approval to use your ${tokenName}.\n\nYou can revoke this permission in the settings.`;
        const approved = await showApprovalModal('Approval Required', body);

        // Approve all
        if(approved){
            showLoader('Requesting allowance...', 'Please review and sign the transaction.');

            const txResponse = await contract.approve(spender, ethers.utils.parseEther('1000000'));
            showLoader('Waiting...', 'Transaction is being processed.');
            await txResponse.wait(2);
            store.dispatch(Analytics.actions.track('approve', {
                tokenName: tokenName,
                spender: spender,
                target: contract.address
            }));
        }
        else{
            hideLoader();
            throw Error('Did not approve');
        }
    }
}

export const checkTokenBalance = async (contract, owner, amount, tokenName = 'PLASM') => {
    showLoader('Checking balance...' );
    const provider = selectProvider(getReduxState());
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    let balance;
    if(_.isNil(contract)){
        // Check TFUEL balance
        balance = await provider.send('eth_getBalance', [address, 'latest']);
        balance = BigNumber.from(balance.toString());
    }
    else{
        balance = await contract.balanceOf(owner);
    }

    const showNotEnoughBalanceAlert = () => {
        store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
            title: `Not enough ${tokenName}`,
            body: `You don't have the required amount of ${formatBalance(amount.toString())} ${tokenName} to spend.`,
            buttons: [
                <RoundedButton title={'Got it'}
                               color={'green'}
                               size={'medium'}
                               style={{width: 132}}
                               onClick={() => {
                                   store.dispatch(UIState.actions.hideModal());
                               }}
                />,
                <br/>,
                <RoundedButton title={'Get More PLASM'}
                               color={'black-with-grey'}
                               size={'small'}
                               style={{width: 236, marginTop: 18, marginBottom: 12}}
                               onClick={() => {
                                   store.dispatch(UIState.actions.hideModal());

                                   setTimeout(() => {
                                       store.dispatch(UIState.actions.showModal(ModalTypes.GetPlasm, {
                                           defaultAmount: `${Math.ceil(parseFloat(ethers.utils.formatEther(amount.toString())))}`,
                                       }));
                                   }, 1000);
                               }}
                />
            ]
        }));
        throw Error(`Not enough ${tokenName}`);
    }

    if(balance.lt(amount)){
        if(contract.address === getPlasmContract(signer)?.address){
            // Don't ask them to unstake anymore
            // const plasmStaking = getPlasmStakingContract(signer);
            // const plasmStaked = await plasmStaking.estimatedPlasmOwnedBy(address);
            // if(plasmStaked.gte(amount)){
            //     const amountToUnstake = BigNumber.from(amount).sub(balance);
            //
            //     store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
            //         title: `Not enough ${tokenName} On hand`,
            //         body: `You don't have the required amount of ${formatBalance(amount.toString())} ${tokenName} to spend.\n\nWould you like to unstake ${formatBalance(amountToUnstake.toString())} PLASM to cover the difference?`,
            //         buttons: [
            //             <RoundedButton title={'Reject'}
            //                            color={'red'}
            //                            size={'medium'}
            //                            style={{width: 112}}
            //                            onClick={() => {
            //                                store.dispatch(UIState.actions.hideModal());
            //                            }}
            //             />,
            //             <RoundedButton title={'Unstake'}
            //                            color={'green'}
            //                            size={'medium'}
            //                            style={{width: 112}}
            //                            onClick={() => {
            //                                const percentageToUnstake = Math.ceil((parseFloat(formatWeiWithoutCommas(amountToUnstake.toString())) / parseFloat(formatWeiWithoutCommas(plasmStaked.toString()))) * 100);
            //                                store.dispatch(Wallet.actions.unstakePlasm(percentageToUnstake));
            //                                store.dispatch(UIState.actions.hideModal());
            //                            }}
            //             />,
            //             <br/>,
            //             <RoundedButton title={'Get More PLASM'}
            //                            color={'black-with-grey'}
            //                            size={'small'}
            //                            style={{width: 236, marginTop: 18, marginBottom: 12}}
            //                            onClick={() => {
            //                                store.dispatch(UIState.actions.hideModal());
            //
            //                                setTimeout(() => {
            //                                    store.dispatch(UIState.actions.showModal(ModalTypes.GetPlasm, {
            //                                        defaultAmount: `${Math.ceil(parseFloat(ethers.utils.formatEther(amountToUnstake.toString())))}`,
            //                                    }));
            //                                }, 1000);
            //
            //
            //                            }}
            //             />
            //         ]
            //     }));
            //     throw Error(`Unstake required`);
            // }
            // else{
            //     showNotEnoughBalanceAlert();
            // }

            showNotEnoughBalanceAlert();
        }
        else{
            showNotEnoughBalanceAlert();
        }
    }
}

export const getNetwork = () => {
    // TODO support others?
    return thetajs.networks.Mainnet;
}

const getContractInfoByKey = (contractKey) => {
    return selectContractByKey(getReduxState(), contractKey);
}

const getContractInfoByAddress = (contractAddress) => {
    return selectContract(getReduxState(), contractAddress);
}

const getContractByAddress = (contractAddress, providerOrSigner) => {
    const contractInfo = getContractInfoByAddress(contractAddress);
    if(_.isNil(contractInfo)){
        return null;
    }

    const {address, abi} = contractInfo;
    return new ethers.Contract(address, abi, providerOrSigner);
}

const getContract = (contractKey, providerOrSigner) => {
    try {
        const {address, abi} = getContractInfoByKey(contractKey);
        return new ethers.Contract(address, abi, providerOrSigner);
    }
    catch (e){
        return null;
    }
}

const getAllNFTContracts = (providerOrSigner) => {
    return _.map(selectNFTContracts(getReduxState()), ({address, abi}) => {
        return new ethers.Contract(address, abi, providerOrSigner)
    });
}

export const getPlasmContract = (providerOrSigner) => {
    let contract = getContract('plasm', providerOrSigner);

    if(_.isNil(contract)){
        const address = '0x5c2fb1e2594e85c5f1579b07dd5b8dfea3f929e0';
        const abi = [
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }
        ];
        contract = new ethers.Contract(address, abi, providerOrSigner);
    }

    return contract;
}

export const getPlasmStakingContract = (providerOrSigner) => {
    let contract = getContract('plasmStaking', providerOrSigner);

    if(_.isNil(contract)){
        const address = '0x1EBCA6aDB9CAD1CF28BE7996378C84CaF2085E25';
        const abi = [
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "balanceOf",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },
            {
                "constant": true,
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "account",
                        "type": "address"
                    }
                ],
                "name": "estimatedPlasmOwnedBy",
                "outputs": [
                    {
                        "internalType": "uint256",
                        "name": "",
                        "type": "uint256"
                    }
                ],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            },

        ];
        contract = new ethers.Contract(address, abi, providerOrSigner);
    }

    return contract;
}

export const getPassawayStakingContract = (providerOrSigner) => {
    return getContract('passawayStaking', providerOrSigner);
}

export const getSubchainGuardianQuery = (providerOrSigner) => {
    return getContract('subchainGuardianQuery', providerOrSigner);
}

export const getCraftingContract = (providerOrSigner) => {
    return getContract('crafting', providerOrSigner);
}

export const getRedeemingContract = (providerOrSigner) => {
    return getContract('redeeming', providerOrSigner);
}

export const getSupplyStoreContract = (providerOrSigner) => {
    return getContract('supplyStore', providerOrSigner);
}

export const getNFTDropManagerContract = (providerOrSigner) => {
    return getContract('tpmcNFTDropManager', providerOrSigner);
}

export const getTPMCLotteryContract = (providerOrSigner) => {
    return getContract('tpmcLottery', providerOrSigner);
}

export const getPassawayTransphormerContract = (providerOrSigner) => {
    return getContract('passawayTransphormer', providerOrSigner);
}

export const getPassawaysContract = (providerOrSigner) => {
    return getContract('passaways', providerOrSigner);
}

export const getTransphormedPassawaysContract = (providerOrSigner) => {
    return getContract('transphormedPassaways', providerOrSigner);
}

export const getESSUTransphormKitContract = (providerOrSigner) => {
    return getContract('essuTransphormKit', providerOrSigner);
}

export const getApprovalCheckerContract = (providerOrSigner) => {
    return getContract('approvalChecker', providerOrSigner);
}

export const getTdropContract = (providerOrSigner) => {
    return getContract('tdrop', providerOrSigner);
}

// ACTIONS
// ===========================
export const LOGIN_WITH_METAMASK = 'LOGIN_WITH_METAMASK';
export const LOGIN_WITH_THETA_DROP = 'LOGIN_WITH_THETA_DROP';
export const FINISH_LOGIN = 'FINISH_LOGIN';
export const WALLET_CONNECTION_CHANGE = 'WALLET_CONNECTION_CHANGE';
export const INIT_WALLET_COMPLETE = 'INIT_WALLET_COMPLETE';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_BALANCES = 'SET_BALANCES';
export const SET_7734_GUARDIAN_TIER = 'SET_7734_GUARDIAN_TIER';
export const SET_REDEEMABLE_REDEMPTIONS = 'SET_REDEEMABLE_REDEMPTIONS';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const SET_PAIR_BALANCE = 'SET_PAIR_BALANCE';
export const SET_PAIR_TOKENS_BALANCE = 'SET_PAIR_TOKENS_BALANCE';
export const SET_PAIR_TOTAL_SUPPLY = 'SET_PAIR_TOTAL_SUPPLY';
export const SET_PAIR_ALLOWANCE = 'SET_PAIR_ALLOWANCE';
export const SET_STAKING_APY = 'SET_STAKING_APY';
export const SET_BLOCK_NUMBER = 'SET_BLOCK_NUMBER';
export const SET_SUBCHAIN_VALIDATOR_STAKE_WITHDRAWLS = 'SET_SUBCHAIN_VALIDATOR_STAKE_WITHDRAWLS';

// ===========================
// SELECTORS
// ===========================
export const selectProvider = (state) => state.models.provider;
export const selectBalances = (state) => state.models.balances;
export const selectGuardianTier = (state) => state.models.guardianTier;
export const selectSubchainStakeWithdrawals = (state) => state.models.subchainStakeWithdrawals;

export const selectTfuelPlasmPair = (state) => state.models.pairs[ThetaSwap.PAIRS.TFUEL_PLASM.address];
export const selectRedemptions = (state) => state.models.redeeming.redemptions;
export const selectLoginType = (state) => {
    return state.models.loginType;
};
export const selectStakingAPY = (state) => {
    return state.models.stakingAPY;
};
export const selectCanSendTransactions = (state) => {
    const loginType = selectLoginType(state)
    return (loginType === LoginTypes.MetaMask || loginType === LoginTypes.ThetaWallet);
};

// ===========================
// MODEL
// ===========================
const Wallet = {
    selectors: {
        selectProvider: (state) => state.models.provider,
    },
    actions: {
        fetchNetwork: () => async dispatch => {
            let network = getNetwork()
            dispatch({
                type: SET_NETWORK,
                network
            });
        },
        getBlockNumber: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const blockNumber = await provider.getBlockNumber();

            dispatch({
                type: SET_BLOCK_NUMBER,
                blockNumber: blockNumber.toString()
            });

            return blockNumber;
        },

        setConnected: (connected) => async dispatch => {
            dispatch({
                type: WALLET_CONNECTION_CHANGE,
                connected,
            });
        },
        initMetamaskWallet: () => async (dispatch, getState) => {
            await dispatch(Wallet.actions.fetchNetwork());
        },
        finishLogin: (loginType, address, signature, message) => async (dispatch, getState) => {
            const result = await post({
                url: "/v1/auth/wallet",
                host: Hosts.API,
                body: {
                    address: address,
                    signature: signature,
                    message: message,
                    login_type: loginType
                },
                dispatch,
                action: FINISH_LOGIN,
            });

            const isOwner = result.body.is_owner;
            dispatch({
                type: SET_LOGIN_TYPE,
                loginType
            });
            await dispatch(User.actions.setUserCredentials(address, result.body.token, isOwner));
            const searchParams = new URLSearchParams(window.location.search);
            const nextPath = searchParams.get('to') || Urls.TPMC_HOME;
            await dispatch(Config.actions.fetchConfig());
            dispatch(Wallet.actions.getBalances());
            dispatch(Nfts.actions.fetchNfts(address));

            dispatch(Analytics.actions.track('login', {loginType}));

            if(window.location.pathname === Urls.TPMC_LOGIN){
                pushTo(nextPath);
            }
        },
        connectDiscord: (code) => async (dispatch, getState) => {
            const result = await get({
                url: "/v1/auth/connect/discord",
                host: Hosts.API,
                params: {
                    code: code
                },
                headers: {
                    'x-session-token': _.get(getState(), 'app.userToken')
                },
            });
            if(result.body.success){
                alert('Your Discord account should now be an authorized TPMC member. Welcome.');
                pushTo('/tpmc/home');
            }
            else{
                alert(result.body.message);
            }
        },
        recoverMetamaskProvider: () => async (dispatch, getState) => {
            try {
                await dispatch(Wallet.actions.initMetamaskWallet());
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(accounts.length > 0){
                    if(window.ethereum.networkVersion !== `${CurrentNetworkInfo.chainIdNum}`){
                        await switchEthereumChain(CurrentNetworkInfo.chainIdHex);
                    }
                    const provider = new providers.Web3Provider(window.ethereum);
                    await dispatch({
                        type: SET_PROVIDER,
                        provider,
                    });

                    window.ethereum.on('accountsChanged', function (accounts) {
                        dispatch(User.actions.logout());
                    });
                }
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        connectWithMetamask: () => async (dispatch, getState) => {
            try {
                await dispatch(Wallet.actions.initMetamaskWallet());
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(window.ethereum.networkVersion !== `${CurrentNetworkInfo.chainIdNum}`){
                    await switchEthereumChain(CurrentNetworkInfo.chainIdHex);
                }
                const provider = new providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                await dispatch({
                    type: SET_PROVIDER,
                    provider,
                });
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        loginWithMetamask: () => async (dispatch, getState) => {
            try {
                showLoader('Pending...', 'Please review and sign the message\non MetaMask.');
                await dispatch(Wallet.actions.initMetamaskWallet());
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if(window.ethereum.networkVersion !== `${CurrentNetworkInfo.chainIdNum}`){
                    await switchEthereumChain(CurrentNetworkInfo.chainIdHex);
                }
                const provider = new providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const message = getWalletSigninMessage();
                const signature = await signer.signMessage(message);
                await dispatch({
                    type: SET_PROVIDER,
                    provider,
                });
                await dispatch(Wallet.actions.finishLogin(LoginTypes.MetaMask, address, signature, message));

                window.ethereum.on('accountsChanged', function (accounts) {
                    dispatch(User.actions.logout());
                });
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        recoverWalletConnectProvider: () => async (dispatch, getState) => {
            try {
                const walletConnectProvider = await createWalletConnectV2Provider(dispatch);
                const handleConnect = async () => {
                    const accounts = walletConnectProvider.accounts;
                    if (!_.isEmpty(accounts)) {
                        const provider = new providers.Web3Provider(walletConnectProvider);
                        await dispatch({
                            type: SET_PROVIDER,
                            provider,
                        });
                    }
                };
                if(walletConnectProvider.session &&
                    walletConnectProvider?.session?.expiry > (Date.now() / 1000) &&
                    walletConnectProvider.accounts.length > 0){
                    // recover session?
                    await handleConnect();
                }
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        connectWithWalletConnect: () => async (dispatch, getState) => {
            try {
                const walletConnectProvider = await createWalletConnectV2Provider(dispatch);
                const handleConnect = async () => {
                    const accounts = walletConnectProvider.accounts;
                    if (!_.isEmpty(accounts)) {
                        const provider = new providers.Web3Provider(walletConnectProvider);
                        const signer = provider.getSigner();
                        await dispatch({
                            type: SET_PROVIDER,
                            provider,
                        });
                    }
                };
                if(walletConnectProvider.session &&
                    walletConnectProvider?.session?.expiry > (Date.now() / 1000) &&
                    walletConnectProvider.accounts.length > 0){
                    // recover session?
                    await handleConnect();
                }
                else{
                    await walletConnectProvider.connect();
                    await handleConnect();
                }
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        loginWithWalletConnect: (onlyRecoverSession) => async (dispatch, getState) => {
            try {
                const walletConnectProvider = await createWalletConnectV2Provider(dispatch);
                const handleConnect = async () => {
                    const accounts = walletConnectProvider.accounts;
                    if (!_.isEmpty(accounts)) {
                        showLoader('Pending...', 'Please review and sign the message\non your Theta Wallet app.');
                        const provider = new providers.Web3Provider(walletConnectProvider);
                        const signer = provider.getSigner();
                        const address = await signer.getAddress();
                        const message = getWalletSigninMessage();
                        const signature = await signer.signMessage(message);
                        await dispatch({
                            type: SET_PROVIDER,
                            provider,
                        });
                        await dispatch(Wallet.actions.finishLogin(LoginTypes.ThetaWallet, address, signature, message));
                    }
                };
                if(walletConnectProvider.session &&
                    walletConnectProvider?.session?.expiry > (Date.now() / 1000) &&
                    walletConnectProvider.accounts.length > 0){
                    // recover session?
                    await handleConnect();
                }
                else{
                    if(!onlyRecoverSession){
                        await walletConnectProvider.connect();
                        await handleConnect();
                    }
                }
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        maybeFinishLoginWithThetaWallet: () => async (dispatch, getState) => {
            try {
                dispatch(Wallet.actions.loginWithWalletConnect(true));
            }
            catch (e){
                toast.error(e.message);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        loginWithThetaDrop: () => async (dispatch, getState) => {
            const searchParams = new URLSearchParams(window.location.search);
            const redirectUrl = window.location.origin + window.location.pathname;
            const message = getWalletSigninMessage();
            await ThetaPass.signMessage(message, redirectUrl, encodeURIComponent(searchParams.get('to')), false);
        },
        finishLoginWithThetaDrop: (response) => async (dispatch, getState) => {
            if(response){
                const {request, result} = response;
                const address = result.address;
                const signature = result.signature;
                const message = request.params[0];
                await dispatch({
                    type: SET_PROVIDER,
                    provider: buildReadonlyProvider(address)
                });
                await dispatch(Wallet.actions.finishLogin(LoginTypes.ThetaDrop, address, signature, message));
            }
        },
        loginWithThetaRarity: () => async (dispatch, getState) => {
            const redirectUrl = window.location.origin + window.location.pathname;
            const nonce = getWalletSigninNonce();
            const uri = `https://thetararity.com/verify?app_id=44248defd501f7d5&nonce=${nonce}&redirect=${encodeURI(redirectUrl)}`;
            window.location.href = uri;
        },
        finishLoginWithThetaRarity: (address, signature, nonce) => async (dispatch, getState) => {
            if(address && signature && nonce){
                const message = `Sign this message to verify your ownership of the wallet.\n\nNonce: ${nonce}`;
                await dispatch({
                    type: SET_PROVIDER,
                    provider: buildReadonlyProvider(address)
                });
                await dispatch(Wallet.actions.finishLogin(LoginTypes.ThetaRarity, address, signature, message));
            }
        },
        signTransaction: () => async  (dispatch, getState) => {
            // TODO sign depending on how the user logged in
        },
        getBalances: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const signer = provider.getSigner();
            const address = await signer.getAddress();

            if(!IsPLASMLive){
                dispatch({
                    type: SET_BALANCES,
                    balances: {
                        plasmOnHand: "0",
                        plasmPending: "0",
                        plasmStaked: "0",
                    }
                });
                return;
            }

            // Passaway Staking
            let pendingRewardsTotal;
            if(IsNFTStakingLive){
                const passawayStaking = getPassawayStakingContract(signer);
                const tokenIds = await passawayStaking.stakesOf(address);
                const pendingRewards = await passawayStaking.calculateRewards(address, tokenIds);
                pendingRewardsTotal = _.reduce(pendingRewards, (current, aPendingReward) => {
                    return current.add(aPendingReward);
                }, BigNumber.from("0"));
            }
            else{
                pendingRewardsTotal = BigNumber.from("0");
            }

            // PLASM
            const plasm = getPlasmContract(signer);
            const plasmBalance = await plasm.balanceOf(address);

            // PLASM Staking
            const plasmStaking = getPlasmStakingContract(signer);
            const plasmStaked = await plasmStaking.estimatedPlasmOwnedBy(address);

            // Subchain staking
            const subchainGuardianQuery = getSubchainGuardianQuery(signer);
            const vsmBalance = await subchainGuardianQuery.estimatedGovernanceTokenOwnedBy(address);

            dispatch({
                type: SET_BALANCES,
                balances: {
                    plasmOnHand: plasmBalance.toString(),
                    plasmPending: pendingRewardsTotal.toString(),
                    plasmStaked: plasmStaked.toString(),
                    plasmStakedToSubchainValidator: vsmBalance.toString(),
                }
            });

            const plasmStakedToSubchainValidatorNum = parseFloat(ethers.utils.formatEther(vsmBalance.toString()));
            const tier = get7734GuardianTier(plasmStakedToSubchainValidatorNum);
            dispatch({
                type: SET_7734_GUARDIAN_TIER,
                tier: tier
            })
        },
        getEphemeralPowerUpSlotPrice: (nft) => async (dispatch, getState) => {
            try {
                showLoader('Fetching price...');
                const {token_id} = nft;
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                // Passaway Staking
                const passawayStaking = getPassawayStakingContract(signer);
                return await passawayStaking.getEphemeralPowerUpSlotPrice(token_id);
            }
            catch (e){
                toast.error('Failed to fetch Ephemeral Power-up Slot price.');
                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getPermanentPowerUpSlotPrice: (nft) => async (dispatch, getState) => {
            try {
                showLoader('Fetching price...');
                const {token_id} = nft;
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                // Passaway Staking
                const passawayStaking = getPassawayStakingContract(signer);
                return await passawayStaking.getPermanentPowerUpSlotPrice(token_id);
            }
            catch (e){
                toast.error('Failed to fetch Perma Power-up Slot price.');

                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        stakePassaways: (tokenIds) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passaways = getPassawaysContract(signer);
                const transphormedPassaways = getTransphormedPassawaysContract(signer);
                const passawayStaking = getPassawayStakingContract(signer);
                const passawayTokenIds = _.filter(tokenIds, (tokenId) => {
                    return parseInt(tokenId) < 100000;
                });
                const transphormedPassawayTokenIds = _.filter(tokenIds, (tokenId) => {
                    return parseInt(tokenId) >= 100000;
                });
                if(passawayTokenIds.length > 0){
                    await checkNFTApproval(passaways, address, passawayStaking.address);
                }
                if(transphormedPassawayTokenIds.length > 0){
                    await checkNFTApproval(transphormedPassaways, address, passawayStaking.address);
                }

                await wrapSendTransaction( () => {
                    return passawayStaking.stake(tokenIds);
                });
                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Passaway${tokenIds.length === 0 ? '' : 's'} staked successfully`);
                dispatch(Analytics.actions.track('stakePassaways', {tokenIds}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e, `Failed to stake Passaway${tokenIds.length === 0 ? '' : 's'}`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        unstakePassaways: (tokenIds) => async (dispatch, getState) => {
            try{
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                let stakeState = await passawayStaking.getStakeState(tokenIds[0]);
                if(stakeState?.permanentPowerUp?.nftContract !== AddressZero){
                    toast.error(`Cannot unstake a Passaway with a Perma Power-up. Remove the Perma Power-up first.`);
                    throw new Error('Cannot unstake a Passaway with a Perma Power-up');
                }

                await wrapSendTransaction( () => {
                    return passawayStaking.unstake(tokenIds);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Passaway${tokenIds.length === 0 ? '' : 's'} unstaked successfully`);
                dispatch(Analytics.actions.track('unstakePassaways', {tokenIds}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to unstake Passaway${tokenIds.length === 0 ? '' : 's'}`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        claimPassawayStakingRewards: () => async (dispatch, getState) => {
            try{
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                showLoader('Loading...', 'Fetching staked passaways.');
                const tokenIds = await passawayStaking.stakesOf(address);
                await wrapSendTransaction( () => {
                    return passawayStaking.claimRewards(tokenIds);
                });
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Staking rewards claimed`);
                dispatch(Analytics.actions.track('claimPassawayStakingRewards', null));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to claim staking rewards`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        purchasePermanentPowerUpSlot: (tokenId) => async (dispatch, getState) => {
            try{
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                const plasm = getPlasmContract(signer);
                const totalPlasmWei = await passawayStaking.getPermanentPowerUpSlotPrice(tokenId);

                await checkTokenBalance(plasm, address, totalPlasmWei);
                await checkTokenApproval(plasm, address, passawayStaking.address, totalPlasmWei);

                await wrapSendTransaction( () => {
                    return passawayStaking.purchasePermanentPowerUpSlot(tokenId);
                });
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(UIState.actions.hideModal(ModalTypes.PowerUpSlotProduct));

                toast.success(`Perma Power-up Slot unlocked`);

                dispatch(Analytics.actions.track('purchasePermanentPowerUpSlot', {tokenId}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to unlock Perma Power-up Slot`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        addPermanentPowerUp: (tokenId, powerUpAddress, powerUpTokenId) => async (dispatch, getState) => {
            try{
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                const powerUp = getContractByAddress(powerUpAddress, signer);
                await checkNFTApproval(powerUp, address, passawayStaking.address);

                if(_.isNil(powerUpTokenId)){
                    showLoader('Loading...', 'Fetching power-up to use.');
                    const aPowerUpTokenId = await powerUp.tokenOfOwnerByIndex(address, 0);
                    powerUpTokenId = aPowerUpTokenId.toString();
                }

                await wrapSendTransaction( () => {
                    return passawayStaking.addPermanentPowerUp(tokenId, powerUpAddress, powerUpTokenId);
                });
                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(UIState.actions.hideModal(ModalTypes.UseInventory));

                toast.success(`Perma Power-up added`);
                dispatch(Analytics.actions.track('addPermanentPowerUp', {tokenId, powerUpAddress, powerUpTokenId}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to add Perma Power-up`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        removePermanentPowerUp: (tokenId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                await wrapSendTransaction( () => {
                    return passawayStaking.removePermanentPowerUp(tokenId);
                });
                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(UIState.actions.hideModal(ModalTypes.ActivePowerUp));

                toast.success(`Perma Power-up Removed`);
                dispatch(Analytics.actions.track('removePermanentPowerUp', {tokenId}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to remove Perma Power-up`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        purchaseEphemeralPowerUpSlot: (tokenId) => async (dispatch, getState) => {
            try{
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                const plasm = getPlasmContract(signer);
                const totalPlasmWei = await passawayStaking.getEphemeralPowerUpSlotPrice(tokenId);

                await checkTokenBalance(plasm, address, totalPlasmWei);
                await checkTokenApproval(plasm, address, passawayStaking.address, totalPlasmWei);

                await wrapSendTransaction( () => {
                    return passawayStaking.purchaseEphemeralPowerUpSlot(tokenId);
                });
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(UIState.actions.hideModal(ModalTypes.PowerUpSlotProduct));

                toast.success(`Ephemeral Power-up Slot unlocked`);
                dispatch(Analytics.actions.track('purchaseEphemeralPowerUpSlot', {tokenId}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to unlock Ephemeral Power-up Slot`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        addEphemeralPowerUp: (tokenId, powerUpAddress, powerUpTokenId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                // TODO if powerUpTokenId is null, then we can fetch their first one???
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayStaking = getPassawayStakingContract(signer);
                const powerUp = getContractByAddress(powerUpAddress, signer);
                await checkNFTApproval(powerUp, address, passawayStaking.address);

                if(_.isNil(powerUpTokenId)){
                    showLoader('Loading...', 'Fetching power-up to use.');
                    const aPowerUpTokenId = await powerUp.tokenOfOwnerByIndex(address, 0);
                    powerUpTokenId = aPowerUpTokenId.toString();
                }

                await wrapSendTransaction( () => {
                    return passawayStaking.addEphemeralPowerUp(tokenId, powerUpAddress, powerUpTokenId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(UIState.actions.hideModal(ModalTypes.UseInventory));

                toast.success(`Ephemeral Power-up added`);
                dispatch(Analytics.actions.track('addEphemeralPowerUp', {tokenId, powerUpAddress, powerUpTokenId}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to add Ephemeral Power-up`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        purchaseProduct: (product, qty = 1) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const supplyStore = getSupplyStoreContract(signer);
                const totalPlasmWei = BigNumber.from(product.plasmPrice).mul(qty);

                await checkTokenBalance(plasm, address, totalPlasmWei);
                await checkTokenApproval(plasm, address, supplyStore.address, totalPlasmWei);

                await wrapSendTransaction( () => {
                    return supplyStore.purchaseProduct(parseInt(product.id), qty);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Config.actions.fetchConfig());
                await dispatch(UIState.actions.hideModal(ModalTypes.SupplyStoreProduct));

                toast.success(`Product purchased`);
                dispatch(Analytics.actions.track('purchaseProduct', {productId: product.id, qty}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to purchase product`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        purchaseNFTDrop: (nftDrop, currencyType, qty = 1) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const tdrop = getTdropContract(signer);
                const nftDropManager = getNFTDropManagerContract(signer);

                let totalWei;
                if(currencyType === CurrencyType.TDROP){
                    totalWei = BigNumber.from(nftDrop.tdropPrice).mul(qty);
                    await checkTokenBalance(tdrop, address, totalWei, 'TDROP');
                    await checkTokenApproval(tdrop, address, nftDropManager.address, totalWei, 'TDROP');
                }
                else if(currencyType === CurrencyType.PLASM){
                    totalWei = BigNumber.from(nftDrop.plasmPrice).mul(qty);
                    await checkTokenBalance(plasm, address, totalWei, 'PLASM');
                    await checkTokenApproval(plasm, address, nftDropManager.address, totalWei, 'PLASM');
                }
                else if(currencyType === CurrencyType.TFUEL){
                    totalWei = BigNumber.from(nftDrop.tfuelPrice).mul(qty);
                    await checkTokenBalance(null, address, totalWei, 'TFUEL');
                }

                await wrapSendTransaction( () => {
                    const overrides = {
                        value: (currencyType === CurrencyType.TFUEL ? totalWei : 0),
                        gasLimit: 500000
                    }
                    return nftDropManager.purchaseNFT(parseInt(nftDrop.id), qty, currencyType, overrides);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Config.actions.fetchConfig());

                toast.success(`NFT purchased`);
                dispatch(Analytics.actions.track('purchaseNFTDrop', {nftDropId: nftDrop.id, currencyType, qty}));

                return true;
            }
            catch (e){
                console.log(e);
                toast.error(getErrorMessage(e,`Failed to purchase NFT`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        craftItem: (craftableItem) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const crafting = getCraftingContract(signer);

                await checkTokenBalance(plasm, address, craftableItem.price);
                await checkTokenApproval(plasm, address, crafting.address, craftableItem.price);

                // Approve all the inputNfts
                for (let idx = 0; idx < craftableItem.inputs.length; idx++) {
                    const input = craftableItem.inputs[idx];
                    const contractAddress = input.tokenAddress;
                    const inputNft = getContractByAddress(contractAddress, signer);
                    await checkNFTApproval(inputNft, address, crafting.address);
                }

                showLoader('Loading...', 'Fetching items to burn.');
                let inputNftTokenIds = [];
                let inputNftIndexes = {};
                for (let idx = 0; idx < craftableItem.inputs.length; idx++) {
                    const input = craftableItem.inputs[idx];
                    const contractAddress = input.tokenAddress;
                    const tokenIndex = (inputNftIndexes[contractAddress] || 0);
                    const inputNft = getContractByAddress(contractAddress, signer);
                    const inputNftTokenId = await inputNft.tokenOfOwnerByIndex(address, tokenIndex);

                    inputNftTokenIds.push(inputNftTokenId.toString());

                    inputNftIndexes[contractAddress] = tokenIndex + 1;
                }

                await wrapSendTransaction( () => {
                    return crafting.craftItem(craftableItem.id, inputNftTokenIds);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                dispatch(Nft.actions.fetchUnclaimedLockedTokensFromCraftableItemOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Item crafted`);
                dispatch(Analytics.actions.track('craftItem', {craftableItemId: craftableItem.id}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to craft item`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        expediteCraftableItemOrder: (orderId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const crafting = getCraftingContract(signer);
                const price = await crafting.getExpediteOrderPrice(orderId);

                await checkTokenBalance(plasm, address, price);
                await checkTokenApproval(plasm, address, crafting.address, price);

                await wrapSendTransaction( () => {
                    return crafting.expediteOrder(orderId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Craftable item order expedited`);
                dispatch(Analytics.actions.track('expediteCraftableItemOrder', {
                    orderId: orderId
                }));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to expedite craftable item order`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        claimCraftableItemOrder: (orderId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const crafting = getCraftingContract(signer);

                await wrapSendTransaction( () => {
                    return crafting.claimOrder(orderId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                const orders = await dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Craftable item claimed`);
                dispatch(Analytics.actions.track('claimCraftableItemOrder', {
                    orderId: orderId
                }));

                return {
                    success: true,
                    orders: orders
                };
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to claim craftable item`));

                return {
                    success: false
                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        expediteAndClaimCraftableItemOrder: (orderId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const crafting = getCraftingContract(signer);

                await wrapSendTransaction( () => {
                    return crafting.expediteAndClaimOrder(orderId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                const orders = await dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Craftable item claimed`);
                dispatch(Analytics.actions.track('expediteAndClaimCraftableItemOrder', {
                    orderId: orderId
                }));

                return {
                    success: true,
                    orders: orders
                };
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to claim craftable item`));

                return {
                    success: false
                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        reclaimLockedTokenFromCrafting: (lockedTokenId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const crafting = getCraftingContract(signer);

                await wrapSendTransaction( () => {
                    return crafting.reclaimLockedToken(lockedTokenId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                const lockedTokens = await dispatch(Nft.actions.fetchUnclaimedLockedTokensFromCraftableItemOrders(address));
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Locked token reclaimed`);
                dispatch(Analytics.actions.track('reclaimLockedTokenFromCrafting', {
                    lockedTokenId: lockedTokenId
                }));

                return {
                    success: true,
                    lockedTokens: lockedTokens
                };
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to claim craftable item`));

                return {
                    success: false
                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        redeem: (redeemable) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const redeeming = getRedeemingContract(signer);

                // Approve all the inputNfts
                for (let idx = 0; idx < redeemable.inputNftContracts.length; idx++) {
                    const contractAddress = redeemable.inputNftContracts[idx];
                    const inputNft = getContractByAddress(contractAddress, signer);
                    await checkNFTApproval(inputNft, address, redeeming.address);
                }

                if(redeemable.burnable){
                    showLoader('Loading...', 'Fetching items to burn.');
                }
                else{
                    showLoader('Loading...', 'Fetching items to lock up.');
                }
                let inputNftTokenIds = [];
                let inputNftIndexes = {};
                for (let idx = 0; idx < redeemable.inputNftContracts.length; idx++) {
                    const contractAddress = redeemable.inputNftContracts[idx];
                    const tokenIndex = (inputNftIndexes[contractAddress] || 0);
                    const inputNft = getContractByAddress(contractAddress, signer);
                    const inputNftTokenId = await inputNft.tokenOfOwnerByIndex(address, tokenIndex);

                    inputNftTokenIds.push(inputNftTokenId.toString());

                    inputNftIndexes[contractAddress] = tokenIndex + 1;
                }

                await wrapSendTransaction( () => {
                    return redeeming.redeem(redeemable.id, inputNftTokenIds);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Item redeemed`);
                dispatch(Analytics.actions.track('redeem', {redeemableId: redeemable.id}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to redeem item`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());

            }
        },
        reclaimRedemption: (redeemableId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const redeeming = getRedeemingContract(signer);

                // Fetch the redeemable here???
                showLoader('Loading...', 'Fetching redeemable.');
                const redeemable = await redeeming.getRedeemable(redeemableId);

                await wrapSendTransaction( () => {
                    return redeeming.reclaim(redeemable.id.toString());
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Item reclaimed`);
                dispatch(Analytics.actions.track('reclaimRedemption', {redeemableId: redeemable.id}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to reclaim item`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        fetchStakingAPY: () => async (dispatch, getState) => {
            const curStakingAPY = selectStakingAPY(getState());
            if(curStakingAPY > 0){
                // Only fetch once
                return;
            }

            const provider = buildThetaProvider(getState());
            const plasm = getPlasmContract(provider);
            const plasmStaking = getPlasmStakingContract(provider);
            const rewardPerAnnumBN = ethers.utils.parseEther('50000000'); // Assuming this is in ether and needs to be converted to wei
            const stakedAmountBN = await plasm.balanceOf(plasmStaking.address);
            let stakingAPY;

            // Make sure stakedAmountBN is not zero
            if (!stakedAmountBN.isZero()) {
                // Adjust for scale to prevent integer division truncation to 0
                stakingAPY = rewardPerAnnumBN.mul(ethers.constants.WeiPerEther).div(stakedAmountBN).mul(100);
                stakingAPY = stakingAPY.div(ethers.constants.WeiPerEther);
                stakingAPY = Math.floor(parseFloat(stakingAPY.toString()));
            }

            if(stakingAPY){
                dispatch({
                    type: SET_STAKING_APY,
                    stakingAPY: stakingAPY,
                });
            }
        },
        stakePlasm: (amount) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const plasmStaking = getPlasmStakingContract(signer);
                const amountBN = ethers.utils.parseEther(amount);
                await checkTokenApproval(plasm, address, plasmStaking.address, amountBN);

                await wrapSendTransaction( () => {
                    return plasmStaking.stake(amountBN.toString());
                });

                await dispatch(Wallet.actions.getBalances());

                toast.success(`PLASM staked`);
                dispatch(Analytics.actions.track('stakePlasm', {amount}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to stake PLASM`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        unstakePlasm: (amount) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasmStaking = getPlasmStakingContract(signer);

                showLoader('Loading...', 'Fetching PLASM stake.');
                const percentageToUnstake = Math.min(parseFloat(amount), 100) / 100;
                console.log('unstakePlasm :: amount == ', amount);
                console.log('unstakePlasm :: percentageToUnstake == ', percentageToUnstake);
                const sharesBalance = await plasmStaking.balanceOf(address);
                console.log('unstakePlasm :: sharesBalance == ', sharesBalance);
                let sharesBN = new BigNumberJS(sharesBalance.toString());
                console.log('unstakePlasm :: sharesBN == ', sharesBN);
                sharesBN = sharesBN.multipliedBy(percentageToUnstake).integerValue();
                console.log('unstakePlasm :: sharesBN == ', sharesBN);
                await wrapSendTransaction( () => {
                    return plasmStaking.unstake(sharesBN.toString());
                });
                await dispatch(Wallet.actions.getBalances());

                toast.success(`PLASM unstaked`);
                dispatch(Analytics.actions.track('unstakePlasm', {percentage: percentageToUnstake}));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to unstake PLASM`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getTransphormables: (tokenIds) => async (dispatch, getState) => {
            try {
                dispatch(UIState.actions.showLoader('Loading...', 'Querying 7734 Initiative\'s Servers'));
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                // Passaway transphormer
                const passawayTransphormer = getPassawayTransphormerContract(signer);
                console.log('passawayTransphormer == ', passawayTransphormer);
                const canTransphorms = await passawayTransphormer.getTransphormable(tokenIds);
                console.log('canTransphorms == ', canTransphorms);
                console.log('_.zipObject(tokenIds, canTransphorms) == ', _.zipObject(tokenIds, canTransphorms))

                return _.zipObject(tokenIds, canTransphorms);
            }
            catch (e){
                toast.error(`Failed to fetch transphormable passaways`);
                return {

                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getTransphormPrice: (nft) => async (dispatch, getState) => {
            try {
                showLoader('Fetching transphorm price...');
                const {token_id} = nft;
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                // Passaway transphormer
                const passawayTransphormer = getPassawayTransphormerContract(signer);
                return await passawayTransphormer.getTransphormPrice(token_id);
            }
            catch (e){
                toast.error('Failed to fetch transphorm price.');
                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        transphormPassaway: (passawayTokenId) => async (dispatch, getState) => {
            let returnValue = null;
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const passaways = getPassawaysContract(signer);
                const passawayTransphormer = getPassawayTransphormerContract(signer);
                const essuTransphormKit = getESSUTransphormKitContract(signer);
                const price = await passawayTransphormer.getTransphormPrice(passawayTokenId);

                await checkTokenBalance(plasm, address, price);
                await checkTokenApproval(plasm, address, passawayTransphormer.address, price);

                // Approve all the inputNfts
                let inputNftContracts = [passaways.address, essuTransphormKit.address];
                for (let idx = 0; idx < inputNftContracts.length; idx++) {
                    const contractAddress = inputNftContracts[idx];
                    const inputNft = getContractByAddress(contractAddress, signer);
                    await checkNFTApproval(inputNft, address, passawayTransphormer.address);
                }

                // TODO show a modal here...they MUST confirm they will lose the passaway and any unlocked slots, etc
                const accepted = await showTransphormConfirmationModal();
                if(!accepted){
                    throw new Error('User did not accept transphorm consent');
                }

                showLoader('Loading...', 'Fetching items to burn.');
                inputNftContracts = [essuTransphormKit.address]; // We only need to auto find an essuTransphormKit
                let inputNftTokenIds = [];
                let inputNftIndexes = {};
                for (let idx = 0; idx < inputNftContracts.length; idx++) {
                    const contractAddress = inputNftContracts[idx];
                    const tokenIndex = (inputNftIndexes[contractAddress] || 0);
                    const inputNft = getContractByAddress(contractAddress, signer);
                    const inputNftTokenId = await inputNft.tokenOfOwnerByIndex(address, tokenIndex);

                    inputNftTokenIds.push(inputNftTokenId.toString());

                    inputNftIndexes[contractAddress] = tokenIndex + 1;
                }
                const essuTransphormKitTokenId = inputNftTokenIds[0];

                const txReceipt = await wrapSendTransaction( () => {
                    console.log('transphormPassaway :: passawayTokenId == ', passawayTokenId);
                    console.log('transphormPassaway :: essuTransphormKitTokenId == ', essuTransphormKitTokenId);
                    console.log('transphormPassaway :: passawayTokenId == ', typeof passawayTokenId);
                    console.log('transphormPassaway :: essuTransphormKitTokenId == ', typeof essuTransphormKitTokenId);
                    return passawayTransphormer.transphormPassaway(passawayTokenId, essuTransphormKitTokenId, {
                        gasLimit: 5000000
                    });
                });
                window.transphormTxReceipt = txReceipt;
                {
                    let TransphormOrderCreated = _.find(txReceipt.events, (x) => (x.event === 'TransphormOrderCreated'));
                    console.log('txReceipt.events == ', txReceipt.events);
                    console.log('TransphormOrderCreated == ', TransphormOrderCreated);
                    const passawayTokenId = TransphormOrderCreated.args.passawayTokenId.toString();
                    console.log('passawayTokenId == ', passawayTokenId);
                    const transphormedPassawayTokenId = TransphormOrderCreated.args.transphormedPassawayTokenId.toString();
                    console.log('transphormedPassawayTokenId == ', transphormedPassawayTokenId);
                    await dispatch(Nft.actions.createProofOfTransphorm(txReceipt.transactionHash, passawayTokenId, transphormedPassawayTokenId));
                }

                await dispatch(Nft.actions.fetchNfts(address, null));
                const orders = await dispatch(Nft.actions.fetchUnclaimedPassawayTransphormOrders(address));
                console.log('orders == ', orders);
                const order = _.find(orders, (order) => (order.originalPassawayTokenId === `${passawayTokenId}` || order.originalPassawayTokenId === parseInt(`${passawayTokenId}`)))
                console.log('order == ', order);
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));

                // preload the image here so it can load later quickly...
                let transphormedImg = order?.transphormedPassaway?.metadata?.image;
                console.log('Preloading this image: ', transphormedImg);
                if(transphormedImg){
                    await preloadImage(transphormedImg);
                }

                toast.success(`Transphorm order created`);
                dispatch(Analytics.actions.track('transphormPassaway', {
                    passawayTokenId: passawayTokenId,
                    transphormedPassawayTokenId: parseInt(passawayTokenId) + 100000,
                    essuTransphormKitTokenId: essuTransphormKitTokenId
                }));

                returnValue = {
                    success: true,
                    orders: orders,
                    order: order
                };
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to transphorm passaway`));
                returnValue = {
                    success: false
                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
            return returnValue;
        },
        expediteTransphormPassawayOrder: (transphormedPassawayTokenId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const passawayTransphormer = getPassawayTransphormerContract(signer);
                const price = await passawayTransphormer.getExpediteOrderPrice(transphormedPassawayTokenId);

                await checkTokenBalance(plasm, address, price);
                await checkTokenApproval(plasm, address, passawayTransphormer.address, price);

                await wrapSendTransaction( () => {
                    return passawayTransphormer.expediteOrder(transphormedPassawayTokenId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Nft.actions.fetchUnclaimedPassawayTransphormOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Transphorm order expedited`);
                dispatch(Analytics.actions.track('expediteTransphormPassawayOrder', {
                    transphormedPassawayTokenId: transphormedPassawayTokenId
                }));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to expedite transphorm order`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }

        },
        claimTransphormPassawayOrder: (transphormedPassawayTokenId) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const passawayTransphormer = getPassawayTransphormerContract(signer);

                await wrapSendTransaction( () => {
                    return passawayTransphormer.claimOrder(transphormedPassawayTokenId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                const orders = await dispatch(Nft.actions.fetchUnclaimedPassawayTransphormOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Nft.actions.fetchNfts(address, null));

                toast.success(`Transphorm claimed`);
                dispatch(Analytics.actions.track('claimTransphormPassawayOrder', {
                    transphormedPassawayTokenId: transphormedPassawayTokenId
                }));

                return {
                    success: true,
                    orders: orders
                };
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to claim transphormed passaway`));

                return {
                    success: false
                };
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        purchaseLotteryTickets: (lottery, qty = 1) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasm = getPlasmContract(signer);
                const tpmcLottery = getTPMCLotteryContract(signer);

                let totalWei = BigNumber.from(lottery.ticketPrice).mul(qty);
                await checkTokenBalance(plasm, address, totalWei, 'PLASM');
                await checkTokenApproval(plasm, address, tpmcLottery.address, totalWei, 'PLASM');

                await wrapSendTransaction( () => {
                    return tpmcLottery.buyTickets(qty);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Config.actions.fetchLottery(address));

                toast.success(`Raffle tickets purchased`);
                dispatch(Analytics.actions.track('purchaseLotteryTicket', {qty}));

                return true;
            }
            catch (e){
                console.log(e);
                toast.error(getErrorMessage(e,`Failed to purchase raffle ticket`));
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getAllRedemptions: () => async (dispatch, getState) => {
            try {
                dispatch(UIState.actions.showLoader('Loading...', 'Fetching redemptions'));
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const curBlockNumber = await provider.getBlockNumber();

                // Redeeming
                const redeemingContract = getRedeemingContract(signer);
                let redemptions = await redeemingContract.getAllUnclaimedRedemptions(address);
                redemptions = _.map(redemptions, (redemption) => {
                    return Object.assign({}, redemption, {
                        lockEndsAt: getTimestampAtBlock(redemption.lockEnds, curBlockNumber)
                    });
                });

                await dispatch({
                    type: SET_REDEEMABLE_REDEMPTIONS,
                    redemptions: redemptions
                });
                return redemptions;
            }
            catch (e){
                console.log(e);
                toast.error(`Failed to fetch available redemption items`);
                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getApprovalsForSpender: (spenderAddress, tokenAddress, nftAddresses) => async (dispatch, getState) => {
            try {
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasmStaking = getPlasmStakingContract(signer);
                const approvalChecker = getApprovalCheckerContract(signer);
                const spenderContract = getContractByAddress(spenderAddress, signer);
                const tokenContract = getContractByAddress(tokenAddress, signer);
                const nftContracts = _.map(nftAddresses, (nftAddress) => {
                    return getContractByAddress(nftAddress, signer);
                });

                let spenderContracts = _.filter([spenderContract], (contract) => {
                    return !_.isNil(contract);
                });
                let spenderAddresses = _.map(spenderContracts, 'address');
                let tokenAllowances = !_.isNil(tokenAddress) ? await approvalChecker.getTokenAllowances(spenderAddresses, address, tokenContract.address) : [];
                tokenAllowances = _.map(tokenAllowances, (allowance, idx) => {
                    return {
                        id: `${spenderAddresses[idx]}-${tokenContract.address}`,
                        type: 'token', // token or nft
                        spender: {
                            address: spenderAddresses[idx],
                            name: getContractInfoByAddress(spenderAddresses[idx]).name,
                            contract: spenderContracts[idx]
                        },
                        target: {
                            address: tokenContract.address,
                            name: getContractInfoByAddress(tokenContract.address).name,
                            contract: tokenContract
                        },
                        value: allowance,
                        action: spenderAddresses[idx] === plasmStaking.address ? 'stake' : 'spend',
                    };
                });

                const formatApprovals = (spenderContract, nftContracts, approvals) => {
                    return _.map(approvals, (approved, idx) => {
                        return {
                            id: `${spenderContract.address}-${nftAddresses[idx]}`,
                            type: 'nft', // token or nft
                            spender: {
                                address: spenderContract.address,
                                name: getContractInfoByAddress(spenderContract.address).name,
                                contract: spenderContract
                            },
                            target: {
                                address: nftAddresses[idx],
                                name: getContractInfoByAddress(nftAddresses[idx]).name,
                                contract: nftContracts[idx]
                            },
                            value: approved,
                            action: 'transfer OR burn'
                        }
                    });
                }
                let spenderApprovals = nftAddresses ? await approvalChecker.getApprovalForAlls(spenderContract.address, address, nftAddresses) : [];
                spenderApprovals = formatApprovals(spenderContract, nftContracts, spenderApprovals);

                return [
                    ...tokenAllowances,
                    ...spenderApprovals
                ];
            }
            catch (e){
                console.log(e);
                return [];
            }
        },
        getApprovals: () => async (dispatch, getState) => {
            try {
                dispatch(UIState.actions.showLoader('Loading...', 'Fetching approvals'));
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const plasmToken = getPlasmContract(signer);
                const plasmStaking = getPlasmStakingContract(signer);
                const approvalChecker = getApprovalCheckerContract(signer);
                const supplyStore = getSupplyStoreContract(signer);
                const crafting = getCraftingContract(signer);
                const passawayStaking = getPassawayStakingContract(signer);
                const redeeming = getRedeemingContract(signer);
                const passawayTransphormer = getPassawayTransphormerContract(signer);
                const nftDropManager = getNFTDropManagerContract(signer);

                let spenderContracts = _.filter([supplyStore, crafting, passawayStaking, plasmStaking, nftDropManager], (contract) => {
                    return !_.isNil(contract);
                });
                let spenderAddresses = _.map(spenderContracts, 'address');
                let tokenAllowances = await approvalChecker.getTokenAllowances(spenderAddresses, address, plasmToken.address);
                tokenAllowances = _.map(tokenAllowances, (allowance, idx) => {
                    return {
                        id: `${spenderAddresses[idx]}-${plasmToken.address}`,
                        type: 'token', // token or nft
                        spender: {
                            address: spenderAddresses[idx],
                            name: getContractInfoByAddress(spenderAddresses[idx]).name,
                            contract: spenderContracts[idx]
                        },
                        target: {
                            address: plasmToken.address,
                            name: getContractInfoByAddress(plasmToken.address).name,
                            contract: plasmToken
                        },
                        value: allowance,
                        action: spenderAddresses[idx] === plasmStaking.address ? 'stake' : 'spend',
                    };

                });

                const nftContracts = getAllNFTContracts(signer);
                const nftAddresses = _.map(nftContracts, 'address');
                const formatApprovals = (spenderContract, nftContracts, approvals) => {
                    return _.map(approvals, (approved, idx) => {
                        return {
                            id: `${spenderContract.address}-${nftAddresses[idx]}`,
                            type: 'nft', // token or nft
                            spender: {
                                address: spenderContract.address,
                                name: getContractInfoByAddress(spenderContract.address).name,
                                contract: spenderContract
                            },
                            target: {
                                address: nftAddresses[idx],
                                name: getContractInfoByAddress(nftAddresses[idx]).name,
                                contract: nftContracts[idx]
                            },
                            value: approved,
                            action: 'transfer OR burn'
                        }
                    });
                }
                let craftingApprovals = await approvalChecker.getApprovalForAlls(crafting.address, address, nftAddresses);
                craftingApprovals = formatApprovals(crafting, nftContracts, craftingApprovals);
                let redeemingApprovals = await approvalChecker.getApprovalForAlls(redeeming.address, address, nftAddresses);
                redeemingApprovals = formatApprovals(redeeming, nftContracts, redeemingApprovals);
                let passawayStakingApprovals = passawayStaking ? await approvalChecker.getApprovalForAlls(passawayStaking.address, address, nftAddresses) : [];
                passawayStakingApprovals = passawayStaking ? formatApprovals(passawayStaking, nftContracts, passawayStakingApprovals) : [];
                let passawayTransphormerApprovals = passawayTransphormer ? await approvalChecker.getApprovalForAlls(passawayTransphormer.address, address, nftAddresses) : [];
                passawayTransphormerApprovals = passawayTransphormer ? formatApprovals(passawayTransphormer, nftContracts, passawayTransphormerApprovals) : [];

                return [
                    ...tokenAllowances,
                    ...craftingApprovals,
                    ...redeemingApprovals,
                    ...passawayStakingApprovals,
                    ...passawayTransphormerApprovals
                ];
            }
            catch (e){
                toast.error(`Failed to fetch approvals`);
                console.log(e);
                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        revokeApproval: (approval) => async (dispatch, getState) => {
            try {
                const {type, spender, target} = approval;
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();

                await wrapSendTransaction( () => {
                    if(type === 'token'){
                        // Revoke all spending of the token
                        return target.contract.approve(spender.address, ethers.utils.parseEther('0'));
                    }
                    if(type === 'nft'){
                        return target.contract.setApprovalForAll(spender.address, false);
                    }
                });
                await dispatch(Wallet.actions.getBalances());

                toast.success(`Approval revoked`);
                dispatch(Analytics.actions.track('revokeApproval', {type, spender: spender.name, target: target.name}));

                return true;
            }
            catch (e){
                toast.error(`Failed to revoke approval`);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        fetchPairBalance(pairAddress) {
            return async (dispatch, getState) => {
                let provider = selectProvider(getState())
                const signer = provider.getSigner();
                const walletAddress = await signer.getAddress();

                if (!_.isEmpty(walletAddress)) {
                    const pairContract = new ethers.Contract(pairAddress, ThetaSwap.PAIR.abi, provider);
                    const pairBalance = await pairContract.balanceOf(walletAddress);

                    dispatch({
                        type: SET_PAIR_BALANCE,
                        pairAddress: pairAddress,
                        pairBalance: pairBalance.toString()
                    });
                }
            }
        },

        fetchPairTokensBalance(pairAddress, tokenA, tokenB) {
            return async (dispatch, getState) => {
                let provider = selectProvider(getState())

                const tokenContractA = new ethers.Contract(tokenA.address, ThetaSwap.TOKEN.abi, provider);
                const tokenContractB = new ethers.Contract(tokenB.address, ThetaSwap.TOKEN.abi, provider);
                const tokenBalanceA = await tokenContractA.balanceOf(pairAddress);
                const tokenBalanceB = await tokenContractB.balanceOf(pairAddress);

                dispatch({
                    type: SET_PAIR_TOKENS_BALANCE,
                    pairAddress: pairAddress,
                    tokenBalanceA: tokenBalanceA.toString(),
                    tokenBalanceB: tokenBalanceB.toString(),
                    tokenDecimalsA: tokenA.decimals,
                    tokenDecimalsB: tokenB.decimals,
                });

            }
        },

        fetchPairTotalSupply(pairAddress) {
            return async (dispatch, getState) => {
                let provider = selectProvider(getState())
                const pairContract = new ethers.Contract(pairAddress, ThetaSwap.PAIR.abi, provider);
                const totalSupply = await pairContract.totalSupply();

                dispatch({
                    type: SET_PAIR_TOTAL_SUPPLY,
                    pairAddress: pairAddress,
                    pairTotalSupply: totalSupply.toString()
                });
            }
        },
        fetchTfuelPlasmPair: () => async (dispatch, getState) => {
            const pair = ThetaSwap.PAIRS.TFUEL_PLASM;
            await dispatch(Wallet.actions.fetchPairTokensBalance(pair.address, pair.tokenA, pair.tokenB));
            await dispatch(Wallet.actions.fetchPairBalance(pair.address));
            await dispatch(Wallet.actions.fetchPairTotalSupply(pair.address));

            return true;
        },
        swapTfuelForPlasm: (amountInput, amountOutputExact) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const pair = ThetaSwap.PAIRS.TFUEL_PLASM;
                const tokenInput = pair.tokenA;
                const tokenOutput = pair.tokenB;
                const providerOrSigner = selectProvider(getState());
                const signer = providerOrSigner.getSigner();
                const address = await signer.getAddress();
                const routerContract = new ethers.Contract(ThetaSwap.V2_ROUTER.address, ThetaSwap.V2_ROUTER.abi, signer);

                const path = [tokenInput.address, tokenOutput.address];
                const amountInputMax = truncateToDigits(parseFloat(amountInput) + (amountInput * ThetaSwap.SETTINGS.SLIPPAGE_TOLERANCE), tokenInput.decimals)

                const overrides = {
                    value: ethers.utils.parseEther("" + amountInputMax)
                };

                await wrapSendTransaction( () => {
                    return routerContract.swapETHForExactTokens(
                        toWei(amountOutputExact, 18),
                        path,
                        address,
                        Date.now() + ThetaSwap.SETTINGS.TRANSACTION_DEADLINE_IN_MS,
                        overrides
                    )
                });
                await dispatch(Wallet.actions.getBalances());
                await dispatch(UIState.actions.hideModal(ModalTypes.GetPlasm));
                toast.success(`Swapped TFUEL for PLASM`);
                dispatch(Analytics.actions.track('swapTFUELForPLASM', {
                    tfuel: amountInputMax,
                    plasm: amountOutputExact
                }));
            }
            catch (e){
                console.log(e);
                toast.error(`Failed to swap TFUEL for PLASM`);
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getExpeditePassawayTransphormOrderPrice: (order) => async (dispatch, getState) => {
            try {
                showLoader('Fetching price...');
                const {transphormedPassawayTokenId} = order;
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                const passawayTransphormer = getPassawayTransphormerContract(signer);
                const price = await passawayTransphormer.getExpediteOrderPrice(transphormedPassawayTokenId);
                return price;
            }
            catch (e){
                toast.error('Failed to fetch expedite price.');

                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getExpediteCraftableItemOrderPrice: (order) => async (dispatch, getState) => {
            try {
                showLoader('Fetching price...');
                const {id} = order;
                const provider = selectProvider(getState());
                const signer = provider.getSigner();

                const crafting = getCraftingContract(signer);
                const price = await crafting.getExpediteOrderPrice(id);
                return price;
            }
            catch (e){
                toast.error('Failed to fetch expedite price.');

                return null;
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getPendingSubchainValidatorStakeWithdrawals: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            await dispatch(Wallet.actions.getBlockNumber());
            const blockNumber = getState().models.blockNumber;
            const blockNumberUpdatedAt = getState().models.blockNumberUpdatedAt;

            const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
            const stakeWithdrawals = await chainRegistrarOnMainchainContract.getPendingStakeWithdrawals(SubchainId, address);
            console.log('stakeWithdrawals == ', stakeWithdrawals);

            const transformWithdrawal = (withdrawal) => {
                const blocksLeft = parseInt(withdrawal.returnHeight.toString()) - parseInt(blockNumber);
                const returnDate = new Date(blockNumberUpdatedAt.getTime() + (blocksLeft * 6 * 1000));

                return {
                    ...withdrawal,
                    returnDate: returnDate,
                    isReadyForClaim: blocksLeft <= 0
                }
            };

            dispatch({
                type: SET_SUBCHAIN_VALIDATOR_STAKE_WITHDRAWLS,
                stakeWithdrawals: _.flatten([
                    _.map(stakeWithdrawals, transformWithdrawal)
                ])
            });
        },
        depositSubchainValidatorStake: (tier) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const balances = selectBalances(getState());
                const provider = selectProvider(getState());
                const currentTier = selectGuardianTier(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
                const vsm = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, signer);
                const governanceToken = getPlasmContract(signer);

                const validatorAddrs = await getValidatorAddresses(chainRegistrarOnMainchainContract);
                const validatorAddr = getValidatorAddressForAccount(address, validatorAddrs);

                const requiredAmountForTier = tier.requiredAmount - parseFloat(ethers.utils.formatEther(balances.plasmStakedToSubchainValidator));
                const amount = '' + requiredAmountForTier;
                console.log('requiredAmountForTier == ', requiredAmountForTier);
                console.log(requiredAmountForTier);

                const amountBN = ethers.utils.parseEther(amount);
                await checkTokenBalance(governanceToken, address, amountBN);
                await checkTokenApproval(governanceToken, address, vsm.address, amountBN);

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.depositStake(SubchainId, validatorAddr, amountBN.toString());
                });

                await dispatch(Wallet.actions.getBalances());
                dispatch(UIState.actions.hideModal(ModalTypes.VIPDeposit));

                toast.success(`7734 Guardian status upgraded`);
                dispatch(Analytics.actions.track('upgrade7734GuardianStatus', {
                    plasmAmount: amount,
                    currentTier: currentTier,
                    tier: tier.name
                }));

                return true;
            } catch (e) {
                console.log(e)
                toast.error(`Failed to upgrade status`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        withdrawSubchainValidatorStake: () => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const percentage = '100'; // 100%
                const provider = selectProvider(getState());
                await dispatch(Wallet.actions.getBlockNumber());
                const signer = provider.getSigner();
                const currentTier = selectGuardianTier(getState());
                let address = await signer.getAddress();
                const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
                const validatorStakeManager = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, signer);

                dispatch(UIState.actions.hideModal(ModalTypes.VIPDeposit));

                let validatorAddrs = await getValidatorAddresses(chainRegistrarOnMainchainContract);
                let validatorAddr = getValidatorAddressForAccount(address, validatorAddrs);
                validatorAddrs = _.uniq(validatorAddrs);
                for (let i = 0; i < validatorAddrs.length; i++) {
                    const validatorSharesBalance = await validatorStakeManager.getStakerShares(SubchainId, validatorAddrs[i], address);

                    if (validatorSharesBalance.toString() !== '0') {
                        validatorAddr = validatorAddrs[i];
                        break;
                    }
                }
                console.log('validatorAddr ', validatorAddr);

                const percentageToUnstake = (parseFloat(percentage) / 100); // user input is in %
                let allSharesBalance = await validatorStakeManager.shareOf(SubchainId, address);
                console.log('allSharesBalance == ', allSharesBalance.toString());
                let validatorSharesBalance = await validatorStakeManager.getStakerShares(SubchainId, validatorAddr, address);
                console.log('validatorSharesBalance == ', validatorSharesBalance.toString());
                let shares = validatorSharesBalance.mul(ethers.utils.parseEther(`${percentageToUnstake}`));
                shares = shares.div(ethers.utils.parseEther('1'));

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.withdrawStake(SubchainId, validatorAddr, shares);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getPendingSubchainValidatorStakeWithdrawals());


                toast.success(`7734 Guardian status given up`);
                dispatch(Analytics.actions.track('giveUp7734GuardianStatus', {
                    currentTier: currentTier
                }));

                return true;
            } catch (e) {
                console.log(e);
                toast.error(`Failed to give up 7734 Guardian status`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        claimWithdrawnSubchainValidatorStake: () => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                let chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.claimStake(SubchainId);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getPendingSubchainValidatorStakeWithdrawals());

                toast.success(`Pending PLASM claimed`);
                dispatch(Analytics.actions.track('claimWithdrawn7734GuardianPlasm', {

                }));

                return true;
            } catch (e) {
                toast.error(`Failed to claim PLASM`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        openPack: (nft) => async (dispatch, getState) => {
            let address = null;
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                address = await signer.getAddress();

                showLoader('Loading...', 'Fetching pack to open.');
                const inputNft = getContractByAddress(nft.address, signer);
                const inputNftTokenId = await inputNft.tokenOfOwnerByIndex(address, 0);

                await wrapSendTransaction( () => {
                    return inputNft.burn(inputNftTokenId);
                });

                await dispatch(Nft.actions.fetchNfts(address, null));
                dispatch(Nft.actions.fetchUnclaimedCraftableItemOrders(address));
                dispatch(Nft.actions.fetchUnclaimedLockedTokensFromCraftableItemOrders(address));
                await dispatch(Config.actions.fetchConfig());
                await dispatch(Wallet.actions.getBalances());

                toast.success(nft.openedPackMessage || `Pack Opened`);

                dispatch(UIState.actions.hideModal(ModalTypes.OpenPack));

                dispatch(Analytics.actions.track('openPack', {
                    address: nft.address,
                    tokenId: inputNftTokenId
                }));

                return true;
            }
            catch (e){
                toast.error(getErrorMessage(e,`Failed to open pack`));
                await dispatch(Nft.actions.fetchNfts(address, null));
                await dispatch(Wallet.actions.getBalances());
            }
            finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
    },
    helpers: {
        getSwapData: (tokenAddressInput, tokenAddressOutput, amountInput, amountOutput, pair) => {
            const getPairTokenReserve = (pair, tokenAddress) => {
                if (!_.isEmpty(pair)) {
                    if (pair.tokenAddressA === tokenAddress) {
                        console.log('')
                        return pair.tokenBalanceA
                    } else {
                        return pair.tokenBalanceB
                    }
                }
            }
            const getAmountOut = (amountIn, reserveIn, reserveOut) => {
                if (amountIn === 0 || reserveIn === 0 || reserveOut === 0
                    || parseFloat(amountIn) > parseFloat(reserveIn)) return 0;
                const amountInWithFee = amountIn * 997;
                const numerator = amountInWithFee * reserveOut;
                const denominator = reserveIn * 1000 + amountInWithFee;
                return numerator / denominator;
            }
            const getAmountIn = (amountOut, reserveIn, reserveOut) => {
                if (amountOut === 0 || reserveIn === 0 || reserveOut === 0
                    || parseFloat(amountOut) > parseFloat(reserveOut)) return 0;
                const numerator = reserveIn * amountOut * 1000;
                const denominator = (reserveOut - amountOut) * 997;
                return (numerator / denominator);
            }

            let pairReserveIn = getPairTokenReserve(pair, tokenAddressInput);
            let pairReserveOut = getPairTokenReserve(pair, tokenAddressOutput);
            let calculatedInputAmount = truncateToDigits(getAmountIn(amountOutput, pairReserveIn, pairReserveOut));
            let calculatedOutputAmount = amountOutput;
            let tokenPrice = pairReserveIn / pairReserveOut;
            let amountLimit = parseFloat(calculatedInputAmount) + (ThetaSwap.SETTINGS.SLIPPAGE_TOLERANCE * calculatedInputAmount);

            return { pairReserveIn, pairReserveOut,
                calculatedInputAmount, calculatedOutputAmount,
                tokenPrice, amountLimit
            }
        },
    },

    spec: {
        connected: false,
        provider: buildThetaProvider(),
        signer: null,
        currentAccount: null,
        guardianTier: null,
        balances: {
            plasmOnHand: "0",
            plasmPending: "0",
            plasmStaked: "0",
            plasmStakedToSubchainValidator: "0"
        },
        redeeming: {
            redemptions: []
        },
        pairs: {
            [ThetaSwap.PAIRS.TFUEL_PLASM.address]: ThetaSwap.PAIRS.TFUEL_PLASM
        },
        subchainStakeWithdrawals: []
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if(type === SET_LOGIN_TYPE){
            return {
                ...state,
                loginType: action.loginType
            }
        }
        if (type === SET_PROVIDER) {
            return {
                ...state,
                provider: action.provider,
            }
        }
        if (type === INIT_WALLET_COMPLETE) {
            return {
                ...state,
                connected: action.connected,
                provider: action.provider,
                signer: action.signer,
                currentAccount: action.currentAccount
            }
        }
        else if (type === LOGIN_WITH_THETA_DROP) {
            return {
                ...state
            }
        }
        else if (type === SET_BALANCES) {
            return {
                ...state,
                balances: {
                    ...state.balances,
                    ...action.balances
                }
            }
        }
        else if(type === SET_7734_GUARDIAN_TIER){
            return {
                ...state,
                guardianTier: action.tier
            }
        }
        else if (type === SET_REDEEMABLE_REDEMPTIONS) {
            return {
                ...state,
                redeeming: {
                    ...state.redeeming,
                    redemptions: action.redemptions
                }
            }
        }
        else if(type === SET_PAIR_BALANCE){
            return {
                ...state,
                pairs: {
                    ...state.pairs,
                    [action.pairAddress]: {
                        ...state.pairs[action.pairAddress],
                        userBalance: fromWei(action.pairBalance),
                    }
                },
            }

        }
        else if(type === SET_PAIR_TOTAL_SUPPLY){
            return {
                ...state,
                pairs: {
                    ...state.pairs,
                    [action.pairAddress]: {
                        ...state.pairs[action.pairAddress],
                        totalSupply: fromWei(action.pairTotalSupply),
                    }
                },
            }
        }
        else if(type === SET_PAIR_TOKENS_BALANCE) {
            return {
                ...state,
                pairs: {
                    ...state.pairs,
                    [action.pairAddress]: {
                        ...state.pairs[action.pairAddress],
                        tokenBalanceA: fromWei(action.tokenBalanceA, action.tokenDecimalsA),
                        tokenBalanceB: fromWei(action.tokenBalanceB, action.tokenDecimalsB),
                    }
                },
            }
        }
        else if(type === SET_PAIR_ALLOWANCE){
            return {
                ...state,
                pairs: {
                    ...state.pairs,
                    [action.pairAddress]: {
                        ...state.pairs[action.pairAddress],
                        allowance: fromWei(action.allowance),
                    }
                }
            }

        }
        else if(type === SET_STAKING_APY){
            return {
                ...state,
                stakingAPY: action.stakingAPY
            }
        }
        else if (type === SET_SUBCHAIN_VALIDATOR_STAKE_WITHDRAWLS) {
            return {
                ...state,
                subchainStakeWithdrawals: action.stakeWithdrawals
            }
        }
        else if (type === SET_BLOCK_NUMBER) {
            return {
                ...state,
                blockNumber: action.blockNumber,
                blockNumberUpdatedAt: new Date()
            }
        }

        return state;
    }
}
export default Wallet;
